html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loadingCircle {
  0% {
    stroke-dashoffset: 600;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

#kyber-widget {
  position: absolute;
  box-sizing: border-box;
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
  top: 50%;
  max-height: 100%;
  width: 100%;
  max-width: 960px;
}

#kyber-widget-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 99999;
  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: fadein 0.3s;
}

.hidden-overlay {
  animation: fadeout 0.3s forwards !important;
}

@keyframes fadeout {
  to {
    opacity: 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#kyber-widget-overlay::before {
  content: '×';
  position: absolute;
  top: 16px;
  right: 8px;
  display: inline-block;
  padding: 15px 0;
  font-size: 36px;
  color: #fff;
  line-height: 0;
  cursor: pointer;
  z-index: 999;
}

#kyber-widget-iframe {
  position: absolute;
  max-width: 990px;
  height: 650px;
  width: 100%;
  max-height: 90%;
  box-sizing: border-box;
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
  top: 50%;
  border: 0;
}

#kyber-widget.embedded-iframe,
#kyber-widget-iframe.embedded-iframe {
  transform: translateX(-50%);
  top: 0;
}
